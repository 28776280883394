/** @jsxImportSource @emotion/react */
import Image from 'next/image';

import { buildMediaUrl, insertNewlines } from '@common/utlis';
import Picture from '@stories/atoms/Picture';
import CallToActionLP from '@stories/molecules/CallToActionLP';
import USPStripe from '@stories/organisms/USPStripe';
import { mediaDown, mq } from '@stories/theming/settings';

import type { HeroCinematicFragment } from '@gql/hygraph/graphql';

export type HeroCinematic = HeroCinematicFragment;

const HeroCinematic: React.FC<HeroCinematic> = ({
  backgroundImage,
  certifications,
  ctaPrimary,
  ctaSecondary,
  headline,
  subheadline,
  copy,
  uspStripe
}) => {
  // const backgroundImagesArray = Object.keys(breakpoints)
  //   .map(
  //     (viewportName) =>
  //       (backgroundImage as ResponsivePictureFragment)?.sources.find(
  //         (source) => source.viewport === viewportName
  //       )?.url || null
  //   )
  //   .map(
  //     (url) =>
  //       url === null
  //         ? null
  //         : `url(${buildMediaUrl(url)}), linear-gradient(to top, hsl(0 0% 0% / 35%), transparent)`

  //     // : `radial-gradient(at center, hsl(0 0% 0% / 15%), transparent), url(${buildMediaUrl(url)})`
  //   );
  return (
    <>
      <div
        css={(t) =>
          mq({
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            minHeight: ['calc(100vh - 7rem)', null, 'calc(100vh - 8rem)'],
            paddingTop: [t.header.height.mobile, null, t.header.height.desktop],
            position: 'relative'
          })
        }
      >
        {backgroundImage && (
          <div
            css={{
              background: 'linear-gradient(to top, hsl(0 0% 0% / 35%), transparent)',
              bottom: 0,
              left: 0,
              right: 0,
              position: 'absolute',
              top: 0
            }}
          >
            <Picture
              {...backgroundImage}
              cssProps={{
                mixBlendMode: 'darken',
                height: '100%',
                objectFit: 'cover',
                width: '100%'
              }}
              sizes={{ mobile: '100vw' }}
            />
          </div>
        )}
        <div
          css={() =>
            mq({
              color: 'white',
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 0,
              justifyContent: 'center',
              margin: '1.5vh auto 0',
              maxWidth: '29rem',
              padding: '1rem var(--offset)',
              textAlign: 'center',
              zIndex: 1
            })
          }
        >
          {headline && (
            <h1 css={(t) => ({ ...t.headline.h1, color: 'white' })}>{insertNewlines(headline)}</h1>
          )}
          {subheadline && (
            <div css={(t) => mq({ fontSize: t.font.size.large, fontWeight: 'bold', marginTop: '1.5rem' })}>
              {subheadline}
            </div>
          )}
          {copy && (
            <div css={() => mq({ opacity: 0.9, marginTop: '.75rem' })}>
              {copy.split('|').map((text) => (
                <span
                  key={text}
                  css={{
                    display: 'inline-block',
                    ':not(:last-child)::after': {
                      content: '"|"',
                      display: 'inline-block',
                      margin: '0 .5rem',
                      opacity: 0.65
                    }
                  }}
                >
                  {text.trim()}
                </span>
              ))}
            </div>
          )}
        </div>
        <div css={mq({ margin: ['1rem 0 1.5rem', null, '2.5rem 0 1.5rem'] })}>
          {ctaPrimary && <CallToActionLP {...{ ctaPrimary, ctaSecondary: ctaSecondary || undefined }} />}
        </div>
        {certifications && (
          <div
            css={{
              bottom: '4rem',
              display: 'flex',
              gap: '1rem',
              marginBottom: '2.5rem',
              right: '4rem',
              zIndex: '1',
              [mediaDown('tablet')]: { display: 'none' }
            }}
          >
            {certifications?.map(({ url, alt }) => (
              <Image
                key={url}
                src={buildMediaUrl(url)}
                alt={alt}
                css={{
                  position: 'static !important' as unknown as undefined,
                  height: '37px !important',
                  width: 'auto !important'
                }}
                fill
                unoptimized
              />
            ))}
          </div>
        )}
      </div>
      <USPStripe items={uspStripe} />
    </>
  );
};

export default HeroCinematic;

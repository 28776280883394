/** @jsxImportSource @emotion/react */
import { keyframes } from '@emotion/react';
import merge from 'lodash/merge';
import Image from 'next/image';
import { useEffect, useState } from 'react';

import { noScrollbarStyle } from '@common/cssUtilities';
import { mediaDown } from '@stories/theming/settings';

import type { HeroCinematicFragment } from '@gql/hygraph/graphql';
import type { CssProps } from '@stories/@types/custom';

const stripeHeight = '3.5rem';
const scrollTime = 4000;

// fade in animation in emotion
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export type USPStripeItem = USPStripe['items'][0] & CssProps;
const USPStripeItem: React.FC<USPStripeItem> = ({ cssProps, image, label }) => {
  return (
    <div
      css={{
        display: 'flex',
        gap: '.5rem',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        ...(cssProps as object)
      }}
    >
      {image && <Image src={image.url} alt={image.alt} width={24} height={24} unoptimized />}
      <span>{label}</span>
    </div>
  );
};

export type USPStripe = {
  items: HeroCinematicFragment['uspStripe'];
} & CssProps;
const USPStripe: React.FC<USPStripe> = ({ items }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const itemsCssProps = {
    alignItems: 'center',
    display: 'flex',
    [mediaDown('desktop')]: {
      // absolute and centered
      position: 'absolute',
      left: '50%',
      opacity: 0,
      top: '50%',
      transform: 'translate(-50%, -50%)'
    }
  } as const;

  // show the active item with a fade in animation
  const activeItemCssProps = merge({}, itemsCssProps, {
    [mediaDown('desktop')]: {
      animation: `${fadeIn} 500ms ease-in both`
    }
  });

  // change active items Index every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveItemIndex((prev) => (prev + 1) % items.length);
    }, scrollTime);
    return () => clearInterval(interval);
  }, [items.length]);

  return (
    <ul
      css={(t) => ({
        ...noScrollbarStyle,
        alignItems: 'center',
        backgroundColor: t.color.grey.g97,
        display: 'flex',
        columnGap: '6rem',
        height: stripeHeight,
        justifyContent: 'center',
        overflow: 'hidden',
        padding: '0 1rem',
        position: 'relative'
      })}
    >
      {items?.map((item, index) => (
        <li key={item.label} css={index === activeItemIndex ? activeItemCssProps : itemsCssProps}>
          <USPStripeItem {...item} />
        </li>
      ))}
    </ul>
  );
};

export default USPStripe;
